import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Footer = ({ handleScrollOrNavigate }) => {
    return (
        <footer className="bg-orange-50">
            <div className="container mx-auto px-4 py-8">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    {/* Logo Section */}
                    {/* <div className="mb-6 md:mb-0">
                        <Link to="/">
                            <img 
                                src="/logo.png" 
                                alt="Logo" 
                                className="w-16 h-16" 
                            />
                        </Link>
                    </div> */}

                    {/* Center Buttons */}
                    <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
                        <div>
                            <h2 className="text-orange-500 text-xl font-bold mb-4">Menu</h2>
                            <ul className="space-y-2">
                                <li>
                                    <button onClick={() => handleScrollOrNavigate('about')} className="text-sm">About Us</button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollOrNavigate('feature')} className="text-sm">How It Works</button>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="text-orange-500 text-xl font-bold mb-4">Quick Links</h2>
                            <ul className="space-y-2">
                                <li>
                                    <Link to="/terms" className="text-sm">Terms & Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/privacy" className="text-sm">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/refund" className="text-sm">Cancellation and Refund</Link>
                                </li>
                                <li>
                                    <Link to="/shipping" className="text-sm">Shipping and Delivery</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Store Buttons */}
                    <div className="flex justify-center md:justify-start space-x-4 mt-6 md:mt-0">
                        <img src="/playstore.png" alt="Get it on Google Play" className="w-24 h-auto" />
                        <img src="/appstore.png" alt="Download on the App Store" className="w-24 h-auto" />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;